import React from 'react';
import { Link } from 'gatsby';

import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout';
import Seo from '@rocketseat/gatsby-theme-docs/src/components/SEO';

export default function NotFound() {
  return (
    <Layout title="Well... this is awkward.">
      <Seo title="404: Not found" />
      <p>The page you tried to get to doesn't exist. Oops.</p>
      <p>If you think this is a mistake, and the page should exist, 
        you should absolutely <Link to="https://discord.gg/HbSERxp465">come let us know</Link>.</p>
      <p>
        Otherwise, you should <Link to="/">go back to the homepage</Link>.
      </p>
    </Layout>
  );
}
